<template>
  <div class="map-container">
    <div class="d-flex fill-height align-center justify-center">
      <v-progress-circular
        indeterminate
        :size="200"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapContainer'
}
</script>

<style scoped>
.map-container {
  height: calc(100vh - 48px);
  width: 100%;
  z-index: 0;
}

.map-container >>> .leaflet-popup-content-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
}

.map-container >>> .leaflet-popup-content {
  margin: 0 !important;
}
</style>
