<template>
  <v-btn
    fab
    small
    :color="color"
    class="map-controls__button"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot>
      <v-icon>{{ icon }}</v-icon>
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: 'MapControl',
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    icon: {
      type: String,
      default: 'secondary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.map-controls__button {
  pointer-events: all;
}
</style>
