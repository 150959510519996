<template>
  <v-dialog
    v-model="visible"
    scrollable
    max-width="900"
    style="z-index: 210;"
    @keyup.esc="visible = false"
  >
    <v-card>
      <v-card-title class="d-flex word-wrap--break">
        <h6 class="text-h6" style="flex-basis: 95%;">
          <v-progress-circular
            v-if="loadingProfession"
            indeterminate
            :size="20"
          />
          <template v-if="profession">
            <span class="primary--text">{{ profession.code }}</span>&nbsp;
            <span>{{ profession.name }}</span>
          </template>
          <span v-else>[Не удалось получить название]</span>
        </h6>
        <v-spacer />
        <v-btn
          icon
          @click="visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text style="min-height: 75vh;">
        <v-overlay
          v-if="loadingProfession"
          absolute
        >
          <div class="text-center">
            <v-progress-circular
              indeterminate
              :size="80"
              :width="5"
              class="mb-6"
            />
            <p class="text-h5 font-weight-light">
              Подождите, идёт загрузка...
            </p>
          </div>
        </v-overlay>
        <template v-if="profession">
          <h6 class="text-h6 accent--text mt-3">Общая информация</h6>
          <v-simple-table dense>
            <tbody>
              <tr>
                <td><b>Кластер</b></td>
                <td>
                  <v-icon :color="professionCluster.color || 'grey'">mdi-stop</v-icon>&nbsp;
                  {{ professionCluster.name || 'Нет данных' }}
                </td>
              </tr>
              <tr>
                <td><b>Название</b></td>
                <td>{{ profession.name || 'Нет данных' }}</td>
              </tr>
              <tr>
                <td><b>Код</b></td>
                <td>{{ profession.code  || 'Нет данных'}}</td>
              </tr>
              <tr>
                <td><b>Тип</b></td>
                <td>{{ (profession.type || {}).code || 'Нет данных' }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <h6 class="text-h6 accent--text mt-3">Квалификация</h6>
          <v-card class="my-3">
            <v-card-text>
              <div v-html="profession.qualification" />
            </v-card-text>
          </v-card>

          <h6 class="text-h6 accent--text mt-3">Профессиограмма</h6>
          <v-card class="my-3">
            <v-card-text>
              <div v-html="profession.description" />
            </v-card-text>
          </v-card>

          <h6 class="text-h6 accent--text mt-3">Где эту профессию можно получить?</h6>
          <div v-if="loadingOrganizations" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
            />
          </div>
          <v-card v-else class="my-3">
            <v-card-text>
              <v-list dense>
                <v-list-item
                  v-for="org in organizations"
                  :key="org.id"
                  :to="`/map/org/${org.id}`"
                  exact
                  color="primary"
                  :class="org.id === orgId ? 'v-list-item--active' : ''"
                >
                  <v-list-item-content>
                    <v-list-item-title class="word-wrap--break">
                      {{ org.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      small
                      icon
                      class="ma-0"
                      :color="org.id === orgId ? 'primary' : ''"
                    >
                      <v-icon v-if="org.id === orgId">mdi-check</v-icon>
                      <v-icon v-else>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </template>
        <p v-else class="text-center">
          Не удалось получить профессиограмму.
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="visible = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>

    <router-view />
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { sanitizeHtml } from '@/utils/sanitizer'

export default {
  name: 'ProfessionDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    orgId: {
      type: Number,
      default: 0,
      required: true
    },
    profId: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data: () => ({
    loadingProfession: false,
    profession: {},
    professionCluster: {},
    loadingOrganizations: false,
    organizations: {}
  }),
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.loadData()
      }
    },
    profId (val) {
      if (val) {
        this.loadData()
      }
    }
  },
  mounted () {
    if (this.visible) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.loadProfession()
      this.loadOrganizations()
    },
    loadProfession () {
      this.loadingProfession = true
      axios.get(`/api/professions/${this.profId}`).then(({ data }) => {
        this.profession = data.item
        this.professionCluster = data.cluster
        this.profession.description = sanitizeHtml(this.profession.description)
      }).catch(({ message }) => {
        this.profession = {}
        this.professionCluster = {}
        this.$toasted.error(message)
      }).finally(() => {
        this.loadingProfession = false
      })
    },
    loadOrganizations () {
      this.loadingOrganizations = true
      axios.get(`/api/professions/${this.profId}/organizations`).then(({ data }) => {
        this.organizations = data
      }).catch(({ message }) => {
        this.organizations = []
        this.$toasted.error(message)
      }).finally(() => {
        this.loadingOrganizations = false
      })
    }
  }
}
</script>

<style scoped>

</style>
