<template>
  <org-info-dialog
    :value="true"
    :org-id="+$route.params.orgId || 0"
    @input="goBack"
  />
</template>

<script>
import OrgInfoDialog from '@/components/Map/OrgInfoDialog'

export default {
  name: 'Org',
  components: { OrgInfoDialog },
  data: () => ({}),
  methods: {
    goBack () {
      this.$router.push('/map')
    }
  }
}
</script>

<style scoped>

</style>
