<template>
  <v-container fluid class="pa-0 fill-height">
    <v-row class="ma-0 fill-height position-relative">
      <v-col cols="12" class="d-flex pa-0">
        <v-card
          class="fill-height flex-shrink-0"
        >
          <v-navigation-drawer
            permanent
            width="500"
          >
            <v-card class="fill-height d-flex flex-column">
              <v-card-title class="flex-shrink-0">
                Фильтрация меток
              </v-card-title>
              <v-card-text
                class="flex-grow-1"
                style="overflow-y: scroll; max-height: calc(100vh - 168px);"
              >
                <v-autocomplete
                  v-model="filterCluster"
                  :items="clusters"
                  clearable
                  item-value="id"
                  item-text="name"
                  chips
                  filled
                  label="Кластер"
                  :disabled="filtersLoading"
                  :loading="filtersLoading"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      class="chip--auto-height mt-3"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-icon :color="data.item.color">mdi-stop</v-icon>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template #item="data">
                    <v-list-item-avatar>
                      <v-icon :color="data.item.color">
                        mdi-stop
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="data.item.name"
                        style="white-space: normal;"
                      />
                      <v-list-item-subtitle
                        v-text="data.item.group"
                      />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="filterType"
                  :items="types"
                  clearable
                  item-value="id"
                  item-text="name"
                  chips
                  filled
                  label="Тип образования"
                  persistent-hint
                  hint="Среднее или Высшее"
                  :disabled="filtersLoading"
                  :loading="filtersLoading"
                />
                <v-autocomplete
                  v-if="false"
                  v-model="filterRegion"
                  :items="regions"
                  clearable
                  item-value="id"
                  item-text="name"
                  chips
                  filled
                  label="Регион"
                  :disabled="filtersLoading"
                  :loading="filtersLoading"
                />
                <v-autocomplete
                  v-model="filterProfession"
                  :items="professions"
                  :search-input.sync="professionsSearch"
                  clearable
                  item-value="id"
                  item-text="name"
                  chips
                  filled
                  label="Профессия/специальность/..."
                  :no-data-text="noDataTextProfsFilter"
                  persistent-hint
                  hint="Поиск по коду и названию"
                  :disabled="filtersLoading"
                  :loading="professionsLoading"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      class="chip--auto-height mt-3"
                      @click="data.select"
                    >
                      <b class="mr-3">{{ data.item.code }}</b> {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template #item="{ item }">
                    <v-list-item-action>
                      <b>{{ item.code }}</b>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.name"
                        style="white-space: normal;"
                      />
                      <v-list-item-subtitle
                        v-text="(item.type || {}).code"
                        style="white-space: normal;"
                      />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <common-dialog
                  v-model="legendDialog"
                  :persistent="false"
                  middle
                  title="Легенда карты и советы"
                  activator
                >
                  <template #activator="{ attrs, on }">
                    <v-btn
                      block
                      outlined
                      class="mt-5"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Советы и обозначения
                    </v-btn>
                  </template>
                  <p class="text-justify">
                    На карте Вы можете увидеть <b>два вида элементов</b>:
                  </p>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th>Изображение</th>
                        <th>Обозначение</th>
                        <th>Описание</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img src="../../assets/img/legend/map-marker.png" alt="Метка образовательной организации"></td>
                        <td>Метка</td>
                        <td>
                          Это метка образовательной организации,
                          имеет общепринятую форму маркера карты,
                          внутри число на <b>чёрной</b> заливке,
                          показывающее количество профессиональных кластеров
                          (<b>из тех, что доступны Вам</b>),
                          которые охватывает образовательная организация.
                          Цвет (или градиент из нескольких цветов)
                          показывает цвета этих кластеров.
                        </td>
                      </tr>
                      <tr>
                        <td><img src="../../assets/img/legend/map-cluster.png" alt="Группа меток образовательных организаций"></td>
                        <td>Группа меток</td>
                        <td>
                          Это группа меток образовательных организаций,
                          которые находятся слишком близко друг к другу (для текущего масштаба),
                          чтобы рисовать их отдельно.
                          Имеет круглую форму,
                          при наведении показывает границу области, куда попадают сгруппированные метки,
                          внутри число на <b>белой</b> заливке,
                          показывающее количество меток (организаций),
                          которые попали в группу.
                          Цвет (или градиент из нескольких цветов)
                          показывает цвета профессиональных кластеров
                          среди всех сгруппированных меток (организаций).
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <p class="text-justify mt-3">
                    Вот несколько примеров:
                  </p>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th>Изображение</th>
                        <th>Описание</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img src="../../assets/img/legend/map-marker-one.png" alt="Метка образовательной организации"></td>
                        <td>
                          В этой организации все профессии относятся к одному кластеру &ndash;
                          «Технология продовольственных продуктов и потребительских товаров, торговля и товароведение»
                          (это кластер № 6)
                        </td>
                      </tr>
                      <tr>
                        <td><img src="../../assets/img/legend/map-marker.png" alt="Метка образовательной организации"></td>
                        <td>
                          А в этой образовательной организации уже 6 профессиональных кластеров.
                        </td>
                      </tr>
                      <tr>
                        <td><img src="../../assets/img/legend/map-cluster.png" alt="Группа меток образовательной организации"></td>
                        <td>
                          Вот пример группы меток,
                          где внутри 10 образовательных организаций,
                          охватывающих почти все кластеры
                          (не все 12 цветов присутствуют в градиенте).
                        </td>
                      </tr>
                      <tr>
                        <td><img src="../../assets/img/legend/map-cluster-area.png" alt="Группа меток образовательной организации"></td>
                        <td>
                          А вот пример группы меток,
                          которая при наведении мышки рисует границу,
                          куда попали сгруппированные метки (граница строится по самым дальним меткам группы).
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <p class="text-justify mt-3">
                    Для фильтрации меток используйте поля ввода на левой боковой панели.
                  </p>
                  <p class="text-justify">
                    Наведение мышки на метку покажет название организации и
                    число профессиональных кластеров в ней.
                  </p>
                  <p class="text-justify">
                    Нажатие на метку организации откроет небольшую карточку с основной информацией,
                    а также кнопкой перехода на сайт организации (слева внизу, если сайт есть) и
                    кнопкой открытия подробной информации (оранжевая справа внизу),
                    где будут указаны профессии/специальности/направления подготовки,
                    а также все доступные данные об образовательной организации.
                  </p>
                  <p class="text-justify">
                    Нажатие на профессию откроет её профессиограмму (описание, требования, противопоказания и т.д.).
                    Внизу будет список организаций, где эту профессию можно получить.
                    Выбранная в данный момент организация отмечена в списке оранжевым цветом и галочкой справа.
                  </p>
                  <p class="text-justify">
                    Для возврата к предыдущим организациям и процессиям используйте историю браузера
                    (например, кнопки «назад» и «вперёд»):
                  </p>
                  <img src="../../assets/img/help/browser-history-actions.png" alt="Кнопки истории браузера">
                </common-dialog>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  block
                  outlined
                  color="primary"
                  @click="loadFilters"
                >
                  <v-icon left>
                    filter-remove-outline
                  </v-icon>
                  Сбросить фильтры
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-navigation-drawer>
        </v-card>

        <div class="flex-grow-1 secondary position-relative">
          <v-overlay
            v-if="markersLoading"
            absolute
          >
            <div class="text-center">
              <v-progress-circular
                indeterminate
                :size="80"
                :width="5"
                class="mb-6"
              />
              <p class="text-h5 font-weight-light">
                Подождите, идёт загрузка...
              </p>
            </div>
          </v-overlay>
          <div class="map-controls">
            <map-control
              v-if="$vuetify.breakpoint.xsOnly"
              class="mb-1"
              :disabled="!filtersLoading"
              icon="mdi-filter"
              @click="zoomIn"
            />
            <map-control
              class="mb-1"
              :disabled="!map || zoom >= maxZoom"
              icon="mdi-plus"
              @click="zoomIn"
            />
            <map-control
              :disabled="!map || zoom <= minZoom"
              icon="mdi-minus"
              @click="zoomOut"
            />
            <v-spacer />
            <v-speed-dial
              v-model="tileLayersMenu"
              direction="right"
              transition="slide-x-transition"
            >
              <template #activator>
                <v-btn
                  v-model="tileLayersMenu"
                  fab
                  small
                  color="secondary"
                  class="events-auto"
                >
                  <v-icon v-if="tileLayersMenu">
                    mdi-close
                  </v-icon>
                  <v-icon v-else>
                    mdi-layers
                  </v-icon>
                </v-btn>
              </template>
              <v-tooltip
                v-for="(layer, key) in tileLayers"
                :key="key"
                top
              >
                <template #activator="{ attrs, on }">
                  <v-btn
                    fab
                    small
                    :color="tileLayerKey === key ? 'primary' : 'secondary'"
                    class="map-controls__button-layer"
                    v-bind="attrs"
                    v-on="on"
                    @click="changeTileLayer(key)"
                  >
                    <v-img
                      :src="require(`../../assets/img/layers/${key}.png`)"
                      :alt="layer.name"
                      width="32px"
                      height="32px"
                    />
                  </v-btn>
                </template>
                {{ layer.name }}
              </v-tooltip>
            </v-speed-dial>
          </div>
          <map-container id="map" />
        </div>
      </v-col>

      <router-view />

      <div v-show="false">
        <v-card
          ref="popup"
          flat
        >
          <v-card-title class="pa-2 word-wrap--break">
            <h6 class="text-body-1" style="flex-basis: 90%;">
              {{ (selectedItem || {}).title }}
            </h6>
            <v-spacer />
            <v-btn
              icon
              class="ma-0"
              @click="closePopup"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-2 py-0" style="min-height: 200px;">
            <v-fade-transition>
              <div
                v-if="markerLoading"
                class="text-center"
              >
                <v-progress-circular
                  indeterminate
                  :size="60"
                  :width="5"
                  color="primary"
                />
              </div>
              <div v-else-if="((markerData || {}).params || {}).org">
                <div class="d-flex">
                  <div class="flex-shrink-0">
                    <v-img
                      width="100"
                      :src="markerData.params.org.logo"
                      class="mx-2 my-0"
                    />
                  </div>
                  <div class="flex-grow-1">
                    <v-simple-table dense>
                      <tbody>
                        <tr>
                          <td class="text-caption">
                            <b>Адрес:</b>&nbsp;{{ markerData.address }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-caption">
                            <b>Как добраться:</b>&nbsp;{{ markerData.params.org.transport || 'Нет данных' }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-caption">
                            <b>Телефоны:</b>&nbsp;{{ markerData.params.org.phone || 'Нет данных' }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-caption">
                            <b>Есть общежитие?</b>&nbsp;{{ markerData.params.org.hasHostel ? 'Да' : 'Нет' }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </div>
              </div>
              <div v-else-if="markerData">
                <p>
                  Не удалось загрузить данные.
                  Для повтора: закройте эту карточку и снова нажмите на метку.
                </p>
              </div>
            </v-fade-transition>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="((markerData || {}).params || {}).org"
              text
              small
              rounded
              color="info"
              :href="markerData.params.org.site"
              target="_blank"
            >
              Открыть сайт
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="markerData"
              small
              rounded
              color="primary"
              :to="`/map/org/${markerData.id}`"
              exact
            >
              Подробнее
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import MapControl from '@/components/Map/MapControl'
import MapContainer from '@/components/Map/MapContainer'
import CommonDialog from '@/components/CommonDialog'

import axios from 'axios'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster'

export default {
  name: 'Index',
  components: { CommonDialog, MapContainer, MapControl },
  data: () => ({
    ignoreErrors: false,

    map: null,
    markersLayer: null,
    markersLoading: false,
    selectedMarker: null,
    selectedItem: null,
    markerLoading: false,
    markerData: null,

    tileLayers: Object.freeze({
      doubleGis: Object.freeze({
        name: '2GIS',
        tileUrlTmpl: 'http://tile{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}',
        isElliptical: false,
        subdomains: '0123'
      }),
      google: Object.freeze({
        name: 'Google Maps',
        tileUrlTmpl: 'http://mts{s}.google.com/vt/hl=ru&x={x}&y={y}&z={z}',
        isElliptical: false,
        subdomains: '0123'
      }),
      osm: Object.freeze({
        name: 'Open Street Map',
        tileUrlTmpl: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        isElliptical: false,
        subdomains: 'abc'
      })
    }),
    tileLayersMenu: false,
    tileLayerKey: 'doubleGis',
    tileLayer: null,

    zoom: 13,
    minZoom: 10,
    maxZoom: 18,

    filtersLoading: false,
    filterProfession: null,
    filterCluster: null,
    filterRegion: null,
    filterType: null,
    clusters: [],
    regions: [],
    types: [],
    professionsLoading: false,
    professionsSearch: null,
    professions: [],

    legendDialog: false
  }),
  created () {
    this.initMarkerIcon()
  },
  async mounted () {
    this.initMap()
    await this.loadFilters()
    await this.loadMarkers()
  },
  beforeDestroy () {
    this.ignoreErrors = true
    if (this.map) {
      this.map.remove()
    }
  },
  computed: {
    isMap () {
      return this.map && this.map instanceof L.Map
    },
    filters () {
      return {
        profession: this.filterProfession,
        cluster: this.filterCluster,
        region: this.filterRegion,
        type: this.filterType
      }
    },
    noDataTextProfsFilter () {
      return (this.professionsSearch || '').length < 3 ? 'Минимум 3 символа для начала поиска' : 'Таких профессий нет, или они в недоступных для Вас кластерах'
    }
  },
  watch: {
    filters () {
      this.loadMarkers()
    },
    professionsSearch () {
      this.loadProfs()
    }
  },
  methods: {
    initMap () {
      const leafletMap = L.map('map', {
        renderer: L.canvas(),
        attributionControl: false,
        zoomControl: false,
        center: [59.9209, 30.2987],
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom
      })
      leafletMap.on('zoomend', this.setZoom)
      this.map = leafletMap
      this.changeTileLayer(this.tileLayerKey)
    },
    initMarkerIcon () {
      const vm = this
      const width = 32
      const height = 40
      L.CustomMarker = {
        Icon: L.Icon.extend({
          options: {
            iconSize: [width, height],
            iconAnchor: [width / 2, width],
            popupAnchor: [0, -(height / 2)],
            shadowAnchor: [0, 0],
            shadowSize: [width, height]
          },
          initialize (options) {
            options = L.Util.setOptions(this, options)
          },
          createIcon () {
            const e = document.createElement('canvas')
            e.width = width
            e.height = height
            this._setIconStyles(e, 'icon')
            this.draw(e.getContext('2d'), width, height)
            return e
          },
          draw (canvas) {
            let colorOuter = '#838894'
            const colorInner = '#101828'
            const colorText = '#ffffff'
            const options = this.options || {}
            const colors = vm.clusters
            const clusters = options.params.org.clusters || []
            const clustersColors = clusters.map(cluster =>
              (colors.find(i => i.id === cluster) || {}).color ||
              '#838894')
            if (clustersColors.length) {
              const gradient = canvas.createLinearGradient(0, 0, 0, 40)
              const colorsSegment = 1 / ((clustersColors.length - 1) || 1)
              let counter = 0
              clustersColors.forEach((color) => {
                gradient.addColorStop(counter * colorsSegment, `${color}`)
                counter++
              })
              colorOuter = gradient
            }

            // Тень
            canvas.beginPath()
            canvas.shadowBlur = 4
            canvas.shadowColor = 'black'
            canvas.fillStyle = 'black'
            canvas.arc(16, 16, 10, 0.5, Math.PI - 0.5, true)
            canvas.moveTo(5, 19)
            canvas.lineTo(16, 38)
            canvas.lineTo(27, 19)
            canvas.fill()
            canvas.shadowBlur = 0
            canvas.closePath()

            // "Хвост" метки
            canvas.beginPath()
            canvas.fillStyle = colorOuter
            canvas.moveTo(3, 19)
            canvas.lineTo(16, 40)
            canvas.lineTo(29, 19)
            canvas.fill()
            canvas.closePath()

            // Граница метки
            canvas.beginPath()
            canvas.fillStyle = colorOuter
            canvas.arc(16, 16, 13, 0, Math.PI * 2)
            canvas.fill()
            canvas.closePath()

            // Фоновая заливка
            canvas.beginPath()
            canvas.fillStyle = colorInner
            canvas.arc(16, 16, 8, 0, Math.PI * 2)
            canvas.fill()
            canvas.closePath()

            // Текст внутри
            canvas.fillStyle = colorText
            canvas.textAlign = 'center'
            canvas.textBaseline = 'middle'
            canvas.font = 'bold 11px sans-serif'
            canvas.fillText(`${clusters.length}`, 16, 16, 30)
          },
          _createInner () {
            return ''
          },
          _setIconStyles (img, name) {
            const options = this.options
            const size = L.point(options.iconSize)
            let anchor = L.point(options.iconAnchor)
            if (!anchor && size) {
              anchor = size.divideBy(2, true)
            }
            img.className = 'map-marker__' + name + ' ' + options.className
            if (anchor) {
              img.style.marginLeft = (-anchor.x) + 'px'
              img.style.marginTop = (-anchor.y) + 'px'
            }
            if (size) {
              img.style.width = size.x + 'px'
              img.style.height = size.y + 'px'
            }
          }
        }),
        icon: (options) => new L.CustomMarker.Icon(options)
      }
    },

    setZoom () {
      if (this.isMap) this.zoom = this.map.getZoom()
    },
    zoomIn () {
      if (this.isMap) this.map.zoomIn()
    },
    zoomOut () {
      if (this.isMap) this.map.zoomOut()
    },
    changeTileLayer (key) {
      if (!this.isMap || !this.tileLayers[key]) {
        return
      }
      if (this.tileLayer && this.tileLayer instanceof L.TileLayer) {
        this.tileLayer.removeFrom(this.map)
      }
      const layer = this.tileLayers[key]
      this.tileLayer = L.tileLayer(layer.tileUrlTmpl, {
        ...layer
      })
      this.tileLayerKey = key || 'doubleGis'
      this.tileLayer.addTo(this.map)
      const center = this.map.getCenter()
      const zoom = this.map.getZoom()
      this.map.options.crs = layer.isElliptical ? L.CRS.EPSG3395 : L.CRS.EPSG3857
      this.map.setView(center, zoom)
    },

    async loadFilters () {
      this.filterProfession = null
      this.filterCluster = null
      this.filterRegion = null
      this.filterType = null
      this.filtersLoading = true
      await axios.get('/api/map/filters').then(({ data }) => {
        this.clusters = data.clusters || []
        this.regions = data.regions || []
        this.types = data.types || []
      }).catch(({ message }) => {
        if (this.ignoreErrors) return
        this.clusters = []
        this.regions = []
        this.types = []
        this.$toasted.error(message)
      }).finally(() => {
        this.filtersLoading = false
      })
    },
    async loadProfs () {
      if ((this.professionsSearch || '').length < 3) {
        return
      }
      if (this.professionsLoading) {
        return
      }
      this.professionsLoading = true
      await axios.get(
        `/api/professions/search/${encodeURIComponent(this.professionsSearch)}`
      ).then(({ data }) => {
        this.professions = data
      }).catch(({ message }) => {
        if (this.ignoreErrors) return
        this.professions = []
        this.$toasted.error(message)
      }).finally(() => {
        this.professionsLoading = false
      })
    },
    async loadMarkers () {
      this.markersLoading = true
      await axios.post('/api/map/markers', this.filters).then(({ data }) => {
        this.drawMarkers(data)
      }).catch(({ message }) => {
        if (this.ignoreErrors) return
        this.$toasted.error(message)
      }).finally(() => {
        this.markersLoading = false
      })
    },
    async loadMarkerData (marker, id) {
      this.markerData = null
      this.$nextTick(() => {
        marker.bindPopup(() => this.$refs.popup.$el, {
          minWidth: 500,
          maxWidth: 500,
          maxHeight: 500,
          closeButton: false
        }).openPopup()
      })
      this.markerLoading = true
      await axios.get(`/api/map/markers/${id}`).then(({ data }) => {
        this.markerData = data.item
        this.$nextTick(() => {
          if (this.isMap) this.map.invalidateSize()
        })
      }).catch(({ message }) => {
        if (this.ignoreErrors) return
        this.$toasted.error(message)
      }).finally(() => {
        this.markerLoading = false
      })
    },
    drawMarkers (data) {
      if (!this.isMap) {
        return
      }
      if (this.markersLayer) {
        this.markersLayer.removeFrom(this.map)
        this.markersLayer = null
      }

      const colors = this.clusters

      const clusterGroup = L.markerClusterGroup({
        maxClusterRadius: (zoom) => zoom >= 13 ? 20 : 60,
        iconCreateFunction (cluster) {
          const canvasElement = document.createElement('canvas')
          canvasElement.width = 40
          canvasElement.height = 40
          const canvas = canvasElement.getContext('2d')
          const text = cluster.getChildCount()
          let clusters = []
          const markers = cluster.getAllChildMarkers()
          markers.forEach((marker) => {
            clusters.push(...marker.options.item.params.org.clusters || [])
          })
          clusters = Array.from(new Set(clusters))
          const clustersColors = clusters.map(cluster =>
            (colors.find(i => i.id === cluster) || {}).color ||
            '#838894')

          let colorOuter = '#838894'
          const colorText = '#101828'
          const colorInner = '#ffffff'

          if (clustersColors.length) {
            const gradient = canvas.createLinearGradient(0, 0, 0, 40)
            const colorsSegment = 1 / ((clustersColors.length - 1) || 1)
            let counter = 0
            clustersColors.forEach((color) => {
              gradient.addColorStop(counter * colorsSegment, `${color}`)
              counter++
            })
            colorOuter = gradient
          }

          // Граница метки
          canvas.beginPath()
          canvas.fillStyle = colorOuter
          canvas.arc(20, 20, 20, 0, Math.PI * 2)
          canvas.fill()
          canvas.closePath()

          // Фоновая заливка
          canvas.beginPath()
          canvas.fillStyle = colorInner
          canvas.arc(20, 20, 13, 0, Math.PI * 2)
          canvas.fill()
          canvas.closePath()

          // Текст внутри
          canvas.fillStyle = colorText
          canvas.textAlign = 'center'
          canvas.textBaseline = 'middle'
          canvas.font = 'bold 13px sans-serif'
          canvas.fillText(text, 20, 20, 40)

          return L.divIcon({
            html: canvasElement,
            className: 'map-icon__cluster',
            iconSize: L.point(40, 40),
            text
          })
        }
      })

      data.forEach((item) => {
        const marker = L.marker([item.lat, item.lng], {
          icon: L.CustomMarker.icon(item),
          item
        })
        const titleHtml = `<p><b>${item.title}</b></p>`
        const subtitleHtml = '<p>' +
          '<span>Содержит проф. кластеров:</span>' +
          `<b>${item.params.org.clusters.length}</b>` +
          '</p>' +
          '<p class="mb-1 text-caption text-right"><i>Нажмите для просмотра</i></p>'
        marker.bindTooltip(titleHtml + subtitleHtml, {
          className: 'map-tooltip'
        })
        marker.on('click', (e) => {
          this.selectedMarker = marker
          this.selectedItem = item
          this.loadMarkerData(marker, item.id)
        })
        clusterGroup.addLayer(marker)
      })
      this.markersLayer = clusterGroup
      this.map.addLayer(this.markersLayer)
    },
    closePopup () {
      if ((this.selectedMarker || {}).closePopup || null) {
        this.selectedMarker.closePopup()
      }
    }
  }
}
</script>

<style lang="scss">
.map-controls {
  z-index: 1;
  position: fixed;
  padding: 5px;
  pointer-events: none;
  display: flex;
  width: 60px;
  height: calc(100vh - 48px);
  flex-direction: column;
  justify-content: space-between;
}

.map-controls__button-layer {
  pointer-events: all;
  overflow: hidden;
  border: 4px solid transparent;
}

.map-popup {
  display: flex;

  &__logo {
    flex-basis: 40%;
    padding: 0 10px 0 0;
  }

  &__text {
    flex-basis: 60%;
    padding: 0 0 0 10px;
  }
}

.map-tooltip {
  white-space: normal !important;
  max-width: 400px;
  width: 400px;
}

.chip--auto-height {
  height: auto !important;
  white-space: normal !important;
}
</style>
