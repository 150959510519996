const iframeErrorMessage = 'Ваш браузер устарел и не поддерживает технологию, необходимую для отображения профессиограммы'
const tagsWhitelist = {
  A: true,
  ABBR: true,
  B: true,
  BLOCKQUOTE: true,
  BODY: true,
  BR: true,
  CENTER: true,
  CODE: true,
  DIV: true,
  EM: true,
  FONT: true,
  H1: true,
  H2: true,
  H3: true,
  H4: true,
  H5: true,
  H6: true,
  HR: true,
  I: true,
  IMG: true,
  LABEL: true,
  LI: true,
  OL: true,
  P: true,
  PRE: true,
  SMALL: true,
  SOURCE: true,
  SPAN: true,
  STRONG: true,
  TABLE: true,
  TBODY: true,
  TR: true,
  TD: true,
  TH: true,
  THEAD: true,
  UL: true,
  U: true,
  VIDEO: true
}
const attributesWhitelist = {
  align: true,
  color: true,
  controls: true,
  height: true,
  href: true,
  src: true,
  style: true,
  target: true,
  title: true,
  type: true,
  width: true
}
const cssWhitelist = {
  color: true,
  'background-color': true,
  'font-size': true,
  'text-align': true,
  'text-decoration': true,
  'font-weight': true
}
const schemesWhiteList = [
  'http:',
  'https:',
  'data:',
  'm-files:',
  'file:',
  'ftp:'
]
const uriAttributes = {
  href: true,
  action: true
}
const toDiv = { FORM: true }

const startsWithAny = (str, substrings) => {
  for (let i = 0, len = substrings.length; i < len; i++) {
    if (str.indexOf(substrings[i]) === 0) {
      return true
    }
  }
  return false
}

const makeSanitizedCopy = (node, iframeDocument) => {
  let newNode = null
  if (node.nodeType === Node.TEXT_NODE) {
    newNode = node.cloneNode(true)
  } else if (node.nodeType === Node.ELEMENT_NODE && (tagsWhitelist[node.tagName] || toDiv[node.tagName])) {
    // Удаляем неиспользуемые пустые текстовые блоки
    if (
      (node.tagName === 'SPAN' || node.tagName === 'B' || node.tagName === 'I' || node.tagName === 'U') &&
      node.innerHTML.trim() === ''
    ) {
      return document.createDocumentFragment()
    }

    if (toDiv[node.tagName]) {
      newNode = iframeDocument.createElement('DIV')
    } else {
      newNode = iframeDocument.createElement(node.tagName)
    }

    for (let i = 0, len = node.attributes.length; i < len; i++) {
      const attr = node.attributes[i]
      if (attributesWhitelist[attr.name]) {
        if (attr.name === 'style') {
          for (let s = 0, styleLen = node.style.length; s < styleLen; s++) {
            const styleName = node.style[s]
            if (cssWhitelist[styleName]) {
              newNode.style.setProperty(styleName, node.style.getPropertyValue(styleName))
            }
          }
        } else {
          if (uriAttributes[attr.name]) {
            if (attr.value.indexOf(':') > -1 && !startsWithAny(attr.value, schemesWhiteList)) {
              continue
            }
          }
          newNode.setAttribute(attr.name, attr.value)
        }
      }
    }
    for (let i = 0, len = node.childNodes.length; i < len; i++) {
      const subCopy = makeSanitizedCopy(node.childNodes[i], iframeDocument)
      newNode.appendChild(subCopy)
    }
  } else {
    newNode = document.createDocumentFragment()
  }
  return newNode
}

export const sanitizeHtml = (rawHtml, onError) => {
  rawHtml = rawHtml.trim()
  if (!rawHtml || rawHtml === '<br>') return ''

  const iframe = document.createElement('iframe')
  if (!iframe.sandbox) {
    if (onError) {
      onError(iframeErrorMessage)
    } else {
      throw new Error(iframeErrorMessage)
    }
  }
  iframe.sandbox = 'allow-same-origin'
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  const iframeDocument = iframe.contentDocument || iframe.contentWindow.document
  if (iframeDocument.body == null) {
    iframeDocument.write('<body></body>')
  }
  iframeDocument.body.innerHTML = rawHtml
  const resultElement = makeSanitizedCopy(iframeDocument.body, iframeDocument)
  document.body.removeChild(iframe)
  return resultElement.innerHTML
    .replace(/<br[^>]*>(\S)/g, '<br>\n$1')
    .replace(/div><div/g, 'div>\n<div')
}
