<template>
  <v-dialog
    v-model="visible"
    scrollable
    max-width="1024"
    @keyup.esc="visible = false"
  >
    <v-card>
      <v-card-title class="d-flex word-wrap--break">
        <h6 class="text-h6" style="flex-basis: 95%;">
          <v-progress-circular
            v-if="loadingOrg"
            indeterminate
            :size="20"
          />
          <span v-else>
            {{ (orgData || {}).name || '[Не удалось получить название организации]' }}
          </span>
        </h6>
        <v-spacer />
        <v-btn
          icon
          @click="visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text style="min-height: 75vh;">
        <v-overlay
          v-if="loadingOrg || loadingProfs"
          absolute
        >
          <div class="text-center">
            <v-progress-circular
              indeterminate
              :size="80"
              :width="5"
              class="mb-6"
            />
            <p class="text-h5 font-weight-light">
              Подождите, идёт загрузка...
            </p>
          </div>
        </v-overlay>
        <v-row class="fill-height">
          <v-col
            cols="6"
            class="fill-height overflow-y-auto pt-3"
          >
            <template v-if="(professions.spo || {}).count">
              <h6 class="text-body-1 my-3">Среднее проф. образование</h6>
              <v-tabs
                v-model="tab"
                background-color="transparent"
                grow
              >
                <template v-for="group in professions.spo.items">
                  <v-tab
                    v-if="(group.items || []).length"
                    :key="group.key"
                  >
                    {{ group.name }}
                  </v-tab>
                </template>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <template v-for="group in professions.spo.items || []">
                  <v-tab-item
                    v-if="(group.items || []).length"
                    :key="group.key"
                  >
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Название</th>
                          <th>Очно</th>
                          <th>Заочно</th>
                          <th>Вечернее</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="prof in group.items || []"
                          :key="prof.id"
                          :class="{ 'prof-row--disabled text--disabled': prof.disabled }"
                          :title="prof.disabled ? 'Этот кластер Вам не доступен' : ''"
                          @click="openProfession(prof)"
                        >
                          <td>
                            <v-icon :color="prof.clusterColor">mdi-stop</v-icon>&nbsp;
                            {{ prof.name }}
                          </td>
                          <td>{{ prof.timeDay ? 'Да' : '' }}</td>
                          <td>{{ prof.timeNight ? 'Да' : '' }}</td>
                          <td>{{ prof.timeMixed ? 'Да' : '' }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-tab-item>
                </template>
              </v-tabs-items>
            </template>
            <template v-if="(professions.vpo || {}).count">
              <h6 class="text-body-1 my-3">Высшее проф. образование</h6>
              <v-simple-table>
                <thead>
                <tr>
                  <th>Название</th>
                  <th>Очно</th>
                  <th>Заочно</th>
                  <th>Вечернее</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="prof in (professions.vpo || {}).items || []"
                  :key="prof.id"
                  style="cursor: pointer;"
                  :class="{ 'prof-row--disabled text--disabled': prof.disabled }"
                  :title="prof.disabled ? 'Этот кластер Вам не доступен' : ''"
                  @click="openProfession(prof)"
                >
                  <td>
                    <v-icon :color="prof.clusterColor">mdi-stop</v-icon>&nbsp;
                    {{ prof.name }}<br>
                    <span class="text--disabled">{{ prof.type }} ({{ prof.timeVpo }})</span>
                  </td>
                  <td>{{ prof.timeDay ? 'Да' : '' }}</td>
                  <td>{{ prof.timeNight ? 'Да' : '' }}</td>
                  <td>{{ prof.timeMixed ? 'Да' : '' }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </template>
          </v-col>
          <v-col
            cols="6"
            class="fill-height overflow-y-auto"
          >
            <template v-if="orgData.id">
              <div
                v-if="orgData.logo"
                class="text-center"
              >
                <img
                  :src="orgData.logo"
                  alt="Лого организации"
                  class="mx-auto my-3"
                  style="max-width: 300px; max-height: 250px;"
                >
              </div>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td class="text-caption">
                      <b>Адрес:</b>
                    </td>
                    <td class="text-caption">
                      <span v-if="orgData.address">{{ orgData.address }}</span>
                      <span v-else class="text--disabled">Нет данных</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-caption">
                      <b>Как добраться:</b>
                    </td>
                    <td class="text-caption">
                      <span v-if="orgData.transport">{{ orgData.transport }}</span>
                      <span v-else class="text--disabled">Нет данных</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-caption">
                      <b>Телефоны:</b>
                    </td>
                    <td class="text-caption">
                      <span v-if="orgData.phone">{{ orgData.phone }}</span>
                      <span v-else class="text--disabled">Нет данных</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-caption">
                      <b>Есть общежитие?</b>
                    </td>
                    <td class="text-caption">
                      {{ orgData.hasHostel ? 'Да' : 'Нет' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-caption">
                      <b>Сайт:</b>
                    </td>
                    <td class="text-caption">
                      <a
                        v-if="orgData.site"
                        :href="orgData.site"
                        target="_blank"
                      >{{ orgData.site }}</a>
                      <span v-else class="text--disabled">Нет данных</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-caption">
                      <b>Группа Вконтакте:</b>
                    </td>
                    <td class="text-caption">
                      <a
                        v-if="orgData.social"
                        :href="orgData.social"
                        target="_blank"
                      >{{ orgData.social }}</a>
                      <span v-else class="text--disabled">Нет данных</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-caption" colspan="2">
                      <b>Описание:</b>&nbsp;
                      <span v-if="orgData.def">{{ orgData.def }}</span>
                      <span v-else class="text--disabled">Нет данных</span>
                    </td>
                  </tr>
                  <tr v-if="orgData.parent">
                    <td class="text-caption" colspan="2">
                      <b>Это филиал,</b>
                      <router-link
                        :to="`/map/org/${orgData.parent}`"
                      >перейти к главной организации</router-link>.
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="visible = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>

    <router-view />
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'OrgInfoDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    orgId: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data: () => ({
    loadingOrg: false,
    orgData: {},
    loadingProfs: false,
    professions: {},
    tab: 0
  }),
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.loadData()
      }
    },
    orgId (val) {
      if (val) {
        this.loadData()
      }
    }
  },
  mounted () {
    if (this.visible) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.loadOrgInfo()
      this.loadProfs()
    },
    loadOrgInfo () {
      this.loadingOrg = true
      axios.get(`/api/organizations/${this.orgId}`).then(({ data }) => {
        this.orgData = data
      }).catch(({ message }) => {
        this.orgData = {}
        this.$toasted.error(message)
      }).finally(() => {
        this.loadingOrg = false
      })
    },
    loadProfs () {
      this.loadingProfs = true
      axios.get(`/api/organizations/${this.orgId}/professions`).then(({ data }) => {
        this.professions = data
      }).catch(({ message }) => {
        this.professions = {}
        this.$toasted.error(message)
      }).finally(() => {
        this.loadingProfs = false
      })
    },
    openProfession (profession) {
      if (profession.disabled) {
        this.$toasted.error('Этот кластер Вам не доступен')
        return
      }
      const newUrl = `/map/org/${this.$route.params.orgId}/prof/${profession.id}`
      if (this.$route.fullPath !== newUrl) {
        this.$router.push(newUrl)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.prof-row--disabled {
  cursor: not-allowed !important;

  .theme--light & {
    background-color: #eeeeee !important;
    border-color: #eeeeee !important;
  }

  .theme--dark & {
    background-color: #424242 !important;
    border-color: #424242 !important;
  }
}
</style>
